// extracted by mini-css-extract-plugin
export const wrapper = "tr-module--wrapper--3SX4q";
export const fullscreenBtn = "tr-module--fullscreenBtn--ifwXF";
export const banner = "tr-module--banner--3wGGG";
export const content = "tr-module--content--1SsNP";
export const field = "tr-module--field--u8fYy";
export const hr = "tr-module--hr--3VYaU";
export const footer = "tr-module--footer--1qXd4";
export const companyAddress = "tr-module--companyAddress--1mlHm";
export const siteInfo = "tr-module--siteInfo--2QFSG";
export const buttonBar = "tr-module--buttonBar--19UTA";